:root {
  --marquee-border-width: 1px;
  @media screen and (min-width: 400px) {
    --marquee-border-width: 2px;
  }  
  @media screen and (min-width: 500px) {
  }  
  @media screen and (min-width: 600px) {
  }  
  @media screen and (min-width: 700px) {
  }  
  @media screen and (min-width: 800px) {
  }  
  @media screen and (min-width: 900px) {
  }  
  @media screen and (min-width: 1000px) {
  }  
  @media screen and (min-width: 1100px) {
  }  
  @media screen and (min-width: 1200px) {
  }  
  @media screen and (min-width: 1300px) {
    --marquee-border-width: 3px;
  }  



  --marquee-h2-margin-top: 7px;
  @media screen and (min-width: 400px) {
    --marquee-h2-margin-top: 10px;
  }
  @media screen and (min-width: 500px) {
    --marquee-h2-margin-top: 10px;
  }
  @media screen and (min-width: 600px) {
    --marquee-h2-margin-top: 11px;
  }
  @media screen and (min-width: 700px) {
    --marquee-h2-margin-top: 11px;
  }
  @media screen and (min-width: 800px) {
    --marquee-h2-margin-top: 12px;
  }
  @media screen and (min-width: 900px) {
    --marquee-h2-margin-top: 13px;
  }
  @media screen and (min-width: 1000px) {
    --marquee-h2-margin-top: 14px;
  }
  @media screen and (min-width: 1100px) {
    --marquee-h2-margin-top: 15px;
  }
  @media screen and (min-width: 1200px) {
    --marquee-h2-margin-top: 16px;
  }
  @media screen and (min-width: 1300px) {
    --marquee-h2-margin-top: 17px;
  }
}
